import axios from 'axios'
import store from '../store'

export default async(data) => {
  let responseData = {}
  responseData.error = false;
  responseData.data = [];
  let defaultLanguage = '';
  if (store.state.defaultLanguage !== null) {
    defaultLanguage = (store.state.defaultLanguage).toLowerCase();
  }
  await axios({
    url: `${window.cipConfig.VUE_APP_API_ENDPOINT}app/donation-mission/reminder`,
    method: 'POST',
    data,
    headers: {
      'X-localization': defaultLanguage
    }
  }).then((response) => {
    responseData.error = false;
    responseData.message = response.data.message;
  })
  .catch(function(error) {
    if (error.response.data.errors[0].message) {
      responseData.error = true;
      responseData.message = error.response.data.errors[0].message;
    }
  });
  return responseData;
}